export const url = {
  home: "/",
  residential: "/Residential",
  services: "/Services",
  clients: "/Clients",
  ourclients: "/ourclients",
  architech: "/architech",
  Contact: "/Contact",
  standards: "/Standards",
  expertise: "/Expertise",
  formwork: "/Formwork",
  technology: "/Technology",
  management: "/Management",
  whoweare: "/Whoweare",
  award: "/Award",
  csr: "/Csr",
  commercial: "/Commercial",
  institutionalprojects: "/Institutionalprojects",
  industrial: "/Industrial",
  gallery: "/Gallery",
  careers: "/Careers",
  machinery: "/Machinery",
};
